<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
             <SideMenu :indexsec="1"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="
                submit(
                  type_form,
                  team_form,
                  name_form,
                  num_form,
                  nat_form,
                  file1
                )
              "
            >
            <div class="div_EProduct">
              <p>AGREGAR JUGADOR</p>
              </div>

              <div class="div_EPForm">
              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="name_form"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>
                <div class="EPF1_C2">
                  <label class="labelNa color-1" for="inputNa">Nacionalidad</label>
                  <b-form-input
                  
                    v-model="nat_form"
                    required
                    id="inputNa"
                    type="text"
                  />
                </div>
              
              </div>
               <div class="row mx-0 row-two">
                <div class="EPF2_C1">
                  <label class="labelE color-1" for="inputE">Numero</label>
                  <b-form-input   v-model="num_form" required id="inputE" type="text"  pattern="\d*" />
                </div>
                <div class="">
                    <label class="labelR color-1" for="inputR">Equipo</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{team_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.teams" :key="key">
                          <b-dropdown-item @click="changeTeam(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   <div class="">
                    <label class="labelPo color-1" for="inputPo">Posición</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{type_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.types" :key="key">
                          <b-dropdown-item @click="changeType(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
               
                
              </div>
                 <div class="div-image mtrow">
                  <label class="labelImg color-1" for="inputImg">Imagen</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                    required
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div> 


               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El jugador se agrego correctamente</p>
                  </div>
                  <div class="">
                    <div class="btns-dflx">
                      <div class="btn-void"></div>
                      <b-button class="btn-modal" @click="editarOtro()"
                        >AÑADIR OTRO</b-button
                      >
                      <b-button class="btn-modal" @click="toEdit()"
                        >EDITAR</b-button
                      >
                      
                    </div>
                    
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import { mapActions } from "vuex";
import Header from '../../components/Header';
export default {
  name: "AddPlayer",
    components: {
    SideMenu,
    Header,
  },
  data() {
    return {
      name_form: "", 
      num_form:"",
      nat_form:"",
      type_form:"",
      team_form:"",
      type_name:"Posición",
      team_name:"Equipo",
      status: "",
      msg: "",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      added: '',
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
     this.getTypes('Posiciones')
     this.getTeams('Equipos')
  },
  computed:{
        types() {
          return this.$store.getters["type/data"];
        },
        teams() { 
          return this.$store.getters["teams/data"];
        },
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
  },
  methods: {
     ...mapActions('type', ['getTypes']),
     ...mapActions('teams', ['getTeams']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
     toEdit: function () {
       this.$router.push("/editar/jugador/"+this.added.id).catch((err) => {});
    },

    submit: async function ( id_type, id_team, name, num, nationality,image) {
     
      
         if(id_type == '' || id_type == undefined || id_type==null){
              this.status='error'
              this.msg='Elige una posición de jugador.'
         }else if(id_team == '' || id_team == undefined || id_team==null){
              this.status='error'
              this.msg='Elige un equipo para el jugador.'
         }else if(image != null){
           if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
              //Creamos el formData
          var data = new  FormData();
          //Añadimos la imagen seleccionada
          data.append('image', image);
          data.append('name', name);
          data.append('num', num);
          data.append('id_type', id_type);
          data.append('id_team', id_team);
          data.append('nationality', nationality);
         
          data.append('_method', 'POST');
          //Enviamos la petición
        
              
          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'players', item: data});
        
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
          console.log(result.added)
             this.added = result.added
              this.showModal() 
          }
            }
      }else{
       
         this.status='error'
         this.msg='Imagen no subida'
      }
     
       
         
     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
    changeType: function (value, name) {
     
       this.type_form = value;
      this.type_name = name;

    },
    changeTeam: function (value, name) {
      this.team_form = value;
      this.team_name = name;

    },
  },
};
</script>


